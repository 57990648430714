<template>
  <div class="mt-7" v-if="facebook_single_adset">
    <div class="d-flex align-center justify-space-between">
      <v-breadcrumbs :items="items"></v-breadcrumbs>
      <div>
        <v-btn
          small
          depressed
          outlined
          color="#8cc73e"
          class="mr-2 custom-transform-class mt-1"
          @click="btnClick"
        >
          Edit Adset
        </v-btn>
        <v-btn
          small
          depressed
          outlined
          color="red"
          class="mr-2 custom-transform-class mt-1"
          @click="deleteAdset"
        >
          Delete Adset
        </v-btn>
      </div>
    </div>

    <v-col>
      <v-row class="py-8" v-if="facebook_single_adset">
        <v-col>
          <summary-card
            :adsetSummary="facebook_single_adset"
            style="height: 100%"
          />
        </v-col>
        <v-col>
          <summary-card
            :adsetTargeting="facebook_single_adset"
            style="height: 100%"
          />
        </v-col>
      </v-row>
      <v-row v-if="facebook_single_adset.targeting.flexible_spec">
        <v-col>
          <general-card>
            <h5 class="px-5 py-2 header-bg">
              <span>ADSET TARGETING</span>
            </h5>
            <v-row class="pa-5">
              <v-col cols="2"> <h6 class="text--disabled">INTERESTS</h6></v-col
              ><v-col cols="10">
                <h6>
                  <span>
                    <span
                      v-for="(filter, i) in facebook_single_adset.targeting
                        .flexible_spec[0].interests"
                      :key="i"
                      >{{ filter.name }},
                    </span>
                  </span>
                </h6></v-col
              ></v-row
            >
          </general-card></v-col
        >
      </v-row>
    </v-col>

    <v-row
      class="d-flex align-center mt-5"
      v-if="facebook_adset_insights.length"
    >
      <v-col>
        <h4>Adset Stats</h4>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row
      class="d-flex align-center mb-1 mt-3"
      v-if="facebook_adset_insights.length"
    >
      <v-col>
        <h5>Show statistics for selected Date Range</h5>
      </v-col>
      <v-col cols="2" class="d-flex align-center">
        <h6 class="text--disabled mr-2">FROM</h6>
        <v-menu
          ref="menuFrom"
          v-model="menuFrom"
          :close-on-content-click="false"
          :return-value.sync="selectedFromDate"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              v-model="selectedFromDate"
              append-icon="mdi-calendar"
              label="Select Date"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedFromDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuFrom = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menuFrom.save(selectedFromDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" class="d-flex align-center">
        <h6 class="text--disabled mr-2">TO</h6>
        <v-menu
          ref="menuTo"
          v-model="menuTo"
          :close-on-content-click="false"
          :return-value.sync="selectedToDate"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              v-model="selectedToDate"
              append-icon="mdi-calendar"
              label="Select Date"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedToDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuTo = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menuTo.save(selectedToDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider v-if="facebook_adset_insights.length"></v-divider>
    <v-expand-x-transition>
      <v-col v-if="facebook_adset_insights.length">
        <v-row class="mt-3">
          <v-col>
            <total-card
              :title="reach_card_title"
              v-if="facebook_adset_insights.length"
              :stats="facebook_adset_insights[0].reach"
            />
          </v-col>
          <v-col>
            <total-card
              :title="spend_card_title"
              v-if="facebook_adset_insights.length"
              :stats="facebook_adset_insights[0].spend"
            />
          </v-col>
          <v-col>
            <total-card
              :title="clicks_card_title"
              v-if="facebook_adset_insights.length"
              :stats="facebook_adset_insights[0].clicks"
            />
          </v-col>
          <v-col>
            <total-card
              :title="impressions_card_title"
              v-if="facebook_adset_insights.length"
              :stats="facebook_adset_insights[0].impressions"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <total-card
              :title="ctr_card_title"
              v-if="facebook_adset_insights.length"
              :stats="facebook_adset_insights[0].ctr"
              :percentage="percentage"
            />
          </v-col>
          <v-col>
            <total-card
              :title="cpc_card_title"
              v-if="facebook_adset_insights.length"
              :stats="facebook_adset_insights[0].cpc"
              :currency="currency"
            />
          </v-col>
          <v-col>
            <total-card
              :title="cpm_card_title"
              v-if="facebook_adset_insights.length"
              :stats="facebook_adset_insights[0].cpm"
              :currency="currency"
            />
          </v-col>
          <v-col>
            <total-card
              :title="cpp_card_title"
              v-if="facebook_adset_insights.length"
              :stats="facebook_adset_insights[0].cpp"
              :currency="currency"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-expand-x-transition>
    <v-row
      class="d-flex align-center my-1 mt-5"
      v-if="impressions_chart_data && clicks_chart_data"
    >
      <v-col>
        <h4>Trends</h4>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-3">
      <transition name="slide-x-transition">
        <v-col
          cols="12"
          sm="6"
          v-if="impressions_chart_data && clicks_chart_data"
        >
          <general-card>
            <v-col cols="6">
              <h4 class="ml-4">Impressions</h4>
            </v-col>
            <line-chart
              :chartdata="impressions_chart_data"
              :options="impressions_chart_options"
            />
          </general-card>
          <general-card class="mt-5">
            <v-row class="my-1">
              <v-col cols="6" class="d-flex align-center">
                <h4 class="ml-4">Clicks and CTR</h4>
              </v-col>
              <v-col cols="65 pr-3">
                <span class="percentage">
                  <v-icon color="rgb(97,156,238)">mdi-rectangle</v-icon>
                  Clicks
                </span>
                <span class="percentage">
                  <v-icon color="rgb(255,157,24)">mdi-rectangle</v-icon>
                  Click Through Rate
                  <v-icon small color="#B5B5B5">mdi-information</v-icon>
                </span>
              </v-col>
            </v-row>
            <line-chart
              v-if="clicks_chart_data"
              :chartdata="clicks_chart_data"
              :options="clicks_chart_options"
            />
          </general-card>
        </v-col>
      </transition>

      <transition name="scroll-x-reverse-transition">
        <v-col cols="12" sm="6" v-if="age_chart_data">
          <general-card class="scroll-y" style="height: 100%">
            <v-col cols="6">
              <h4 class="ml-4">Impressions By Age</h4>
            </v-col>
            <doughnut-chart
              :chartdata="age_chart_data"
              :options="age_chart_options"
            />
          </general-card>
        </v-col>
      </transition>
    </v-row>
    <v-row class="d-flex align-center mb-1 mt-5">
      <v-col>
        <h4>Ads</h4>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-3 mb-2">
      <v-col cols="12">
        <ads-table :headers="headers" :ads="facebook_adset_ads" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SummaryCard from "../../../components/Layouts/Cards/SummaryCard.vue";
import TotalCard from "../../../components/Layouts/Cards/TotalCard.vue";
import AdsTable from "../../../components/Tables/AdsTable.vue";
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";
import LineChart from "../../../components/Charts/LineChart.vue";
import DoughnutChart from "../../../components/Charts/DoughnutChart.vue";
// import DonutChart from "../../../components/Charts/DonutChart.vue";

export default {
  data: () => ({
    percentage: "%",
    currency: "$",
    items: [
      {
        text: "Facebook Ad Sets",
        disabled: false,
        href: "#/facebook/ad-sets",
      },
      {
        text: "Single Ad Set",
        disabled: true,
        href: "#",
      },
    ],
    //UI
    clicks_card_title: "Total Clicks",
    impressions_card_title: "Total Impressions",
    reach_card_title: "Total Reach",
    spend_card_title: "Total Spent",
    cpm_card_title: "Average CPM",
    ctr_card_title: "CTR",
    cpc_card_title: "Average CPC",
    cpp_card_title: "Average CPP",

    //Chart Inits
    impressions_chart_data: null,
    clicks_chart_data: null,
    age_chart_data: null,

    //General Data Request body
    adAccountId: "",
    date_preset: "maximum",
    breakdowns: "age",
    time_increment: 1,

    //Date Setup
    menuFrom: false,
    menuTo: false,
    selectedFromDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    selectedToDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),

    //Charts & Tables
    headers: [
      {
        text: "Ad",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Created Time",
        value: "created_time",
      },
      {
        text: "Age Range",
        value: "targeting",
      },
      {
        text: "Bid Type",
        value: "bid_type",
      },
    ],

    //Impressions Chart
    impressions_chart_options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: "point",
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              beginAtZero: true,
              padding: 5,
              callback: (label) => `${label} K`,
            },
          },
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              beginAtZero: true,
              padding: 5,
              stepSize: 2,
            },
            position: "right",
          },
        ],
        xAxes: [
          {
            ticks: { fontStyle: "bold" },
            display: true,
          },
        ],
      },
    },

    //Age Chart
    // age_chart_options: {
    //   maintainAspectRatio: false,
    //   responsive: true,
    //   scale: {
    //     display: false,
    //   },
    // },
    age_chart_options: {
      responsive: true,
      maintainAspectRatio: false,
    },

    //Clicks Chart
    clicks_chart_options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: "point",
      },
      elements: {
        line: {
          tension: 0,
        },
        point: {
          radius: 0,
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              beginAtZero: true,
              padding: 5,
            },
          },
          {
            position: "right",
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              beginAtZero: true,
              padding: 5,
            },
          },
        ],
        xAxes: [
          {
            ticks: { fontStyle: "bold" },
          },
        ],
      },
    },
  }),

  async created() {
    this.adsetId = this.$route.params.id;
  },

  async mounted() {
    this.adAccountId = sessionStorage.getItem("adAccountId");

    // Get Adset Details
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("facebook/getFacebookAdset", {
        adAccountId: this.adAccountId,
        adsetId: this.adsetId,
      });
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error
      this.$store.commit("makingApiRequest", false);
    }

    //Get Time Based Adset Insights
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("facebook/getTimeBasedAdsetInsights", {
        adAccountId: this.adAccountId,
        adsetId: this.adsetId,
        date_preset: this.date_preset,
        time_increment: this.time_increment,
      });
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error
      this.$store.commit("makingApiRequest", false);
    }

    //Get Age-based Insights
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("facebook/getRefinedAdsetInsights", {
        adAccountId: this.adAccountId,
        adsetId: this.adsetId,
        date_preset: this.date_preset,
        breakdowns: this.breakdowns,
      });
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error
      this.$store.commit("makingApiRequest", false);
    }

    // Get Adset Ads
    try {
      await this.$store.dispatch("facebook/getFacebookAdsByAdset", {
        adAccountId: this.adAccountId,
        adsetId: this.adsetId,
      });
    } catch (error) {
      this.error = error
    }

    // Get Adset Insights
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("facebook/getAdsetsInsights", {
        adAccountId: this.adAccountId,
        adsetId: this.adsetId,
      });
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
     this.error = error
      this.$store.commit("makingApiRequest", false);
    }

    //Impressions Chart Data
    try {
      const options = { weekday: "short", day: "numeric", month: "short" };
      const date_store =
        this.$store.state.facebook.facebook_time_based_adset_insights.map(
          (date) =>
            new Date(date.date_stop).toLocaleDateString("en-US", options)
        );

      const impressions_store =
        this.$store.state.facebook.facebook_time_based_adset_insights.map(
          (impressions) => parseInt(impressions.impressions) / 1000
        );

      this.impressions_chart_data = {
        labels: date_store,
        datasets: [
          {
            label: "Impressions",
            data: impressions_store,
            fill: true,
            backgroundColor: "rgba(69, 176, 255,.5)",
            borderColor: "rgb(79,146,237)",
            borderWidth: 2,
          },
        ],
      };
    } catch (error) {
     this.error = error
    }

    //Age Chart Data
    try {
      const age_ranges =
        this.$store.state.facebook.facebook_age_adset_insights.map(
          (age_range) => age_range.age
        );
      const age_range_data =
        this.$store.state.facebook.facebook_age_adset_insights.map(
          (age_range_data) => parseInt(age_range_data.impressions)
        );

      this.age_chart_data = {
        labels: age_ranges,
        datasets: [
          {
            label: "Age Ranges",
            data: age_range_data,
            backgroundColor: [
              "#51ada0",
              "#ffcd56",
              "#3271e2",
              "#70df9b",
              "#ff6484",
              "#ff7139",
              "#d03c51",
            ],
          },
        ],
      };
    } catch (error) {
     this.error = error
    }

    //Clicks Chart Data
    try {
      const options = { weekday: "short", day: "numeric", month: "short" };
      const date_store =
        this.$store.state.facebook.facebook_time_based_adset_insights.map(
          (date) =>
            new Date(date.date_stop).toLocaleDateString("en-US", options)
        );
      const clicks_store =
        this.$store.state.facebook.facebook_time_based_adset_insights.map(
          (clicks) => parseInt(clicks.clicks)
        );
      const cpc_store =
        this.$store.state.facebook.facebook_time_based_adset_insights.map(
          (cpc) => parseInt(cpc.ctr) * 100
        );

      this.clicks_chart_data = {
        labels: date_store,
        datasets: [
          {
            data: clicks_store,
            fill: false,
            borderColor: "rgb(97,156,238)",
            borderWidth: 2,
          },
          {
            data: cpc_store,
            fill: false,
            borderColor: "rgb(255,157,24)",
            borderWidth: 3,
          },
        ],
      };
    } catch (error) {
      this.error = error
    }
  },

  watch: {
    // selectedFromDate() {
    //   this.selectedToDate = "";
    // },
    selectedToDate() {
      // Get Insights By Time Range
      try {
        this.$store.commit("makingApiRequest", true);
        this.$store.dispatch("facebook/getAdsetInsightsByDate", {
          adAccountId: this.adAccountId,
          adsetId: this.adsetId,
          time_increment: this.time_increment,
          time_range: {
            since: this.selectedFromDate,
            until: this.selectedToDate,
          },
        });
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
      }
    },
  },

  components: {
    SummaryCard,
    TotalCard,
    AdsTable,
    GeneralCard,
    LineChart,
    DoughnutChart,
    // DonutChart,
  },
  computed: {
    ad_account_id() {
      return this.$store.state.facebook.ad_account_id;
    },
    facebook_single_adset() {
      return this.$store.state.facebook.facebook_single_adset;
    },
    facebook_adset_insights() {
      return this.$store.state.facebook.facebook_adset_insights;
    },
    facebook_adset_ads() {
      return this.$store.state.facebook.facebook_adset_ads;
    },
    facebook_time_based_adset_insights() {
      return this.$store.state.facebook.facebook_time_based_adset_insights;
    },
    facebook_age_adset_insights() {
      return this.$store.state.facebook.facebook_age_adset_insights;
    },
  },
  async beforeDestroy() {
    let payload = {};
    payload.facebook_single_adset = null;
    await this.$store.commit("facebook/setFacebookAdset", payload);

    payload = {};
    payload.facebook_adset_insights = [];
    await this.$store.commit("facebook/setFacebookAdsetInsights", payload);

    payload = {};
    payload.facebook_adset_ads = [];
    await this.$store.commit("facebook/setFacebookAdsetAds", payload);

    payload = {};
    payload.facebook_time_based_adset_insights = [];
    await this.$store.commit("facebook/setTimeBasedAdsetInsights", payload);

    payload = {};
    payload.facebook_age_adset_insights = [];
    await this.$store.commit("facebook/setRefinedAdsetInsights", payload);
  },
  methods: {
    btnClick() {
      this.$router.push(`/facebook/edit-adset/${this.adsetId}?tab=adset`);
    },
    async deleteAdset() {
      let combined_data = {
        adAccountId: this.adAccountId,
        adSetId: this.adsetId,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/deleteAdset", combined_data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Adset successfully deleted!",
          group: "success",
        });

        this.$router.push("/facebook/ad-sets");
      } catch (error) {
        this.$notify({
        title: "Sorry, there was a problem deleting the Adset. Please try again later",
        group: "errors",
      });
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>
